'use strict';

angular.module('enervexSalesappApp').controller('VentMaterialsCtrl', function($scope, VentMaterial, _) {
	$scope.allVentMaterials = [];
	$scope.offset = 0;
	$scope.limit = 50;
	function fetch() {
		VentMaterial.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(ventMaterials){
			if (ventMaterials.length > 0) {
				$scope.allVentMaterials = $scope.allVentMaterials.concat(ventMaterials);
				$scope.offset = $scope.offset + $scope.limit;
				$scope.allVentMaterials = _.sortBy($scope.allVentMaterials, function(o){
					return o.sortOrder * 1
				})
				fetch();
			} else {
				$scope.ventMaterials = $scope.allVentMaterials
			}
		});
	}
	$scope.computeAses = [
		"BWH",
		"DRY",
		"COM"
	]
	fetch();
	$scope.delete = function(item) {
		VentMaterial.delete({
			id: item._id
		});
		$scope.allVentMaterials = _.filter($scope.allVentMaterials, function(r){
			return r._id != item._id
		})
		$scope.ventMaterials = $scope.allVentMaterials;
		// $scope.applyFilter()
	}
	// $scope.applyFilter = function() {
	// 	var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;

	// 	$scope.fans = _.filter($scope.allVentMaterials, function(rule){
	// 		if (nameReg && !nameReg.test(rule.code) ) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'BWH' && !rule.BWH) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'COM' && !rule.COM) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'DRY' && !rule.DRY) {
	// 			return false;
	// 		} else {
	// 			return true
	// 		}
	// 	})
	// }
});
